.banners-wrapper {
    padding-top: 50px;

    &:last-child {
        padding-bottom: 50px;
    }

    @media(max-width: 768px) {
        padding-top: 0;
        padding-bottom: 0;
    }

    .banner-wrapper {
        display: flex;
        color: color('white');
        text-decoration: none;
        position: relative;

        @media(max-width: 768px) {
            margin-top: 50px;
        }

        a {
            width: 100%;
            display: block;
            height: 300px;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;

            .banner-content-wrapper {
                position: absolute;
                bottom: 0;
                padding: 30px;
                font-family: font(main);

                h2 {
                    font-size: 28px;
                    position: relative;
                    font-weight: 600;
                    text-transform: uppercase;

                    @media(max-width: 768px) {
                        font-size: 22px;
                    }
                }

                .separator {
                    height: 2px;
                    width: 100px;
                    margin: 10px 0;
                    background-color: color('terniary');
                }

                p {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.5;
                    text-decoration: none;
                }
            }
        }
    }
}


.home-double-banners {
    .row {
        height: 500px;
        background-size: cover;
        background-repeat: no-repeat;

        .content {
            padding: 100px 350px 100px 50px;
            background-color: #3e4659;
            height: 500px;
            opacity: 0.7;

            @media (max-width: 720px){
                padding: 50px;
            }

            .banner-content-wrapper {
                h2{
                    color: color('white');
                    font-size: 32px;
                    font-weight: 300;
                    margin-bottom: 20px;
                    @media (max-width: 720px){
                        font-size: 25px;
                    }
                }
                p{
                    color: color('white');
                    padding-bottom: 20px;
                    line-height: 1.6;
                }
                .separator{
                    height: 1px;
                    background: white;
                    width: 130px;
                    opacity: 0.4;
                }
                .banner-link{
                    border: 1px solid white;
                    padding: 10px 20px;
                    background-color: white;
                    color: black;
                    font-weight: 600;
                    font-size: 15px;
                    text-decoration: none;

                    &:hover{
                        background-color: #c9c7c7;
                    }
                }

            }
        }

    }
}